import api from './api';

const service = {

  async search(params) {
    let res = await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/same_day_market/trading/plan`);

    res.data.buy_dataList.forEach( item => {
      // 入力チェックメッセージ用
      item._message = Object.keys(item).reduce( (message, key) => {
        message[key] = [];
        return message;
      }, {});
      // 元の値を保持
      item._origin = JSON.parse(JSON.stringify(item));
    });
    
    res.data.sell_dataList.forEach( item => {
      // 入力チェックメッセージ用
      item._message = Object.keys(item).reduce( (message, key) => {
        message[key] = [];
        return message;
      }, {});
      // 元の値を保持
      item._origin = JSON.parse(JSON.stringify(item));
    });
    
    return res;
  },
  async update(params, payload) {
    payload.sell_dataList.forEach( item => {
      delete item._message;// 入力チェックメッセージ用
      delete item._origin;// 元の値
    });

    payload.buy_dataList.forEach( item => {
      delete item._message;// 入力チェックメッセージ用
      delete item._origin;// 元の値
    });

    return await api.put(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/same_day_market/trading/plan`, payload);
  },
  async searchCapacities(params) {
    return await api.get(`/dates/${params.target_date}/same_day_market/interconnector`, { params });
  },
  async searchBoard(params) {
    let res = await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/timeCodeType/${params.time_code_type}/same_day_market/trading/board`);

    res.data.bid_list.forEach( item => {
      // 入力チェックメッセージ用
      item._message = Object.keys(item).reduce( (message, key) => {
        message[key] = [];
        return message;
      }, {});
      // 元の値を保持
      item._origin = JSON.parse(JSON.stringify(item));
    });
    return res;
  },
}

export default service;