import api from './api';

const service = {

    /**
     * 相対販売計画/PMS向け販売計画 ダウンロード
     * @param {string} aggregator_id 
     * @param {number} area_id 
     * @param {Object} params
     * @param {string} params.target_date
     * @param {string} params.file_name 
     * @returns 
     */
    async getPmsLinkageSalePlanDownload(aggregator_id, area_id, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/area_id/${area_id}/pms_linkage/sale_plan`, { params, responseType: 'blob', dataType: 'binary' });
    },
    /**
     * 相対取引データ/PMS向けエリア内相対取引 ダウンロード
     * @param {string} aggregator_id 
     * @param {number} area_id 
     * @param {Object} params
     * @param {string} params.target_date
     * @param {string} params.file_name 
     * @returns 
     */
    async getPmsLinkageNegotiationTradingAreaInsideDownload(aggregator_id, area_id, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/area_id/${area_id}/pms_linkage/negotiation_trading/area_inside`, { params, responseType: 'blob', dataType: 'binary' });
    },
    /**
     * スポット市場取引/スポット市場入札量 ダウンロード
     * @param {string} aggregator_id 
     * @param {number} area_id 
     * @param {Object} params
     * @param {string} params.target_date
     * @param {string} params.file_name 
     * @returns 
     */
    async getPmsLinkageSpotTradingBidQuantityDownload(aggregator_id, area_id, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/area_id/${area_id}/pms_linkage/spot_trading/bid_quantity`, { params, responseType: 'blob', dataType: 'binary' });
    },
    /**
     * スポット市場取引/スポット市場入札量 ダイアログ
     * @param {string} aggregator_id 
     * @param {number} area_id 
     * @param {Object} params
     * @param {string} params.target_date
     * @returns 
     */
    async getPmsLinkageSpotTradingBidQuantityDialog(aggregator_id, area_id, params) {
        return await api.get(`/aggregators/${aggregator_id}/area_id/${area_id}/pms_linkage/spot_trading/bid_quantity/dialog`, { params});
    }
}

export default service;
