import Vue from 'vue';
import Confirm from '@/components/Common/Confirm.vue';
import Dialog from '@/components/Common/Dialog.vue';


export default {
  methods: {
    async $confirm(param) {
      let message, type;
      if (typeof (param) == "string" || param instanceof String) {
        message = param || '';
        type = 'info';
      } else {
        message = param.text || '';
        type = param.type || 'info';
      }
      return new Promise((resolve) => {
        const VM = Vue.extend(Confirm);
        new VM({
          parent: this,
          propsData: {
            message,
            type,
            okAction: () => resolve(true),
            cancelAction: () => resolve(false),
          },
        });
      }).catch((err) => {
        throw err;
      });
    },
    async $confirmWarning(message) {
      let type = 'warning';
      return new Promise((resolve) => {
        const VM = Vue.extend(Confirm);
        new VM({
          parent: this,
          propsData: {
            message,
            type,
            okAction: () => resolve(true),
            cancelAction: () => resolve(false),
          },
        });
      }).catch((err) => {
        throw err;
      });
    },
    async $dialog(param) {
      let message, type;
      if (typeof (param) == "string" || param instanceof String) {
        message = param || '';
        type = 'info';
      } else {
        message = param.text || '';
        type = param.type || 'info';
      }
      return new Promise((resolve) => {
        const VM = Vue.extend(Dialog);
        new VM({
          parent: this,
          propsData: {
            message,
            type,
            okAction: () => resolve(true),
          },
        });
      }).catch((err) => {
        throw err;
      });
    },
    async $dialogSuccess(message) {
      let type = 'success';
      return new Promise((resolve) => {
        const VM = Vue.extend(Dialog);
        new VM({
          parent: this,
          propsData: {
            message,
            type,
            okAction: () => resolve(true),
          },
        });
      }).catch((err) => {
        throw err;
      });
    },
    async $dialogWarning(message) {
      let type = 'warning';
      return new Promise((resolve) => {
        const VM = Vue.extend(Dialog);
        new VM({
          parent: this,
          propsData: {
            message,
            type,
            okAction: () => resolve(true),
          },
        });
      }).catch((err) => {
        throw err;
      });
    },
    async $dialogError(message) {
      let type = 'error';
      return new Promise((resolve) => {
        const VM = Vue.extend(Dialog);
        new VM({
          parent: this,
          propsData: {
            message,
            type,
            okAction: () => resolve(true),
          },
        });
      }).catch((err) => {
        throw err;
      });
    },
  },
};