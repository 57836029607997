<template>
<!--Start Toolbar-->
  <v-toolbar class="vpp-g-header vpp-color-3" style="background:white !important" color="black"> 
    <!--Start VPP Title-->
    <title>Renewable Energy Balancing System</title>
    <v-layout row wrap align-center style="padding: 12px;">
      <a href="https://www.global.toshiba/jp/top.html" title="東芝トップページ" style="padding-top: 6px;" tabindex="-1">
        <img src="@/assets/logo_pc.gif" alt="Toshiba" @click="toshibaLink" @keydown.enter="toshibaLink" style="cursor: pointer;padding-left:1rem" tabindex="1">
      </a>
      <div style="height: 18px;">
        <img src="@/assets/Toshiba-identifire-image.png" alt="Renewable Energy Balancing System" style="padding-left: 49px;height: 18px;">
      </div>
    </v-layout>
    <!--End VPP Title-->
    <!--Start Contact-->
    <div v-show="path">  <!--Login 画面用-->
      <v-btn v-show="showContact" @click="contactNewWindow()" tabindex="2" style="width: 140px; font-size: 14px; float: right; cursor:pointer;background-color:#f5f5f5;!important;color:black !important">
        <v-icon style="color:black;padding-right: 0.5rem;">mdi-help-circle</v-icon>
        お問い合わせ
      </v-btn>
    </div>
    <!--End Contact-->        
    <!--Start NowTime,ユーザメーニュ-->
    <div v-show="!path"><!--Main 画面用-->
      <v-layout style="padding:0px;">   
      </v-layout>
      <v-layout>
        <span style="padding: 7px 20px 0px 0px;color:black; font-weight:bold">{{ aggregatorName }}</span>
        <span style="padding: 7px 20px 0px 0px;color:black; font-weight:bold">{{ userName }}</span>
        <span style="padding: 7px 20px 0px 0px;color:black; font-weight:bold">{{ date + ' '+ time }}</span>
        <v-btn @click="loginNewWindow()" tabindex="2" style="width: 140px; font-size: 14px; float: right; cursor:pointer;background-color:#f5f5f5;!important;color:black !important">
          <v-icon style="color:black;padding-right: 0.5rem;">mdi-exit-to-app</v-icon>
          ログアウト
        </v-btn>
      </v-layout>
    </div>
    <!--End NowTime,ユーザメーニュ-->
  </v-toolbar>
<!--End Toolbar-->
</template>

<script>
"use strict";
import config from "@/config"
import factory from '@/services/factory';
const service = factory.get('auth');

export default {
  components: {},
  data: () => ({
    path: true,
    showContact: true,
    width: window.innerWidth,
    height: window.innerHeight,
    items: [{ icon: "exit_to_app", title: "ログアウト" }],
    essLinkInfo: config.link.ess,
    tochibaLinkInfo: config.link.toshiba,
    userName : null,
    aggregatorName: null,
    date: '',
    time: '',
    week: ['(日)', '(月)', '(火)', '(水)', '(木)',  '(金)', '(土)']  //追加

  }),

  /**
   * 最初設定
   */
  mounted: function() {
    window.addEventListener("resize", this.handleResize);

    if (localStorage.getItem("username")) {
      this.userName = localStorage.getItem("username") + ' 様';
    }
    this.aggregatorName = localStorage.getItem("aggregatorName");
    
    setInterval(this.updateTime, 1000); 
  },
  computed: {
    binding() {
      const binding = {};
      if (this.$vuetify.breakpoint.smAndDown) binding.column = true;
      return binding;
    },
  },
  created() {
    if(location.pathname != '/' && location.pathname != '/user-password' && location.pathname != '/contact' && location.pathname != '/login'
        && location.pathname != '/rebset1/' && location.pathname != '/rebset1/user-password' && location.pathname != '/rebset1/contact'
        && location.pathname != '/rebset2/' && location.pathname != '/rebset2/user-password' && location.pathname != '/rebset2/contact'){
      this.path = false;
    }

    if(location.pathname == '/contact' || location.pathname == '/rebset1/contact' || location.pathname == '/rebset2/contact'){
      this.showContact = false;
    }
  },
  methods: {
    updateTime: function() { 
      //現在の日付・時刻を取得 
      let current_date = new Date();
      //「時：分：秒」に整形する
      this.time = this.zeroPadding(current_date.getHours(), 2) + ':' + this.zeroPadding(current_date.getMinutes(), 2) + ':' + this.zeroPadding(current_date.getSeconds(), 2)
      // 「〜年〜月〜日 (曜日)」に整形する 
      this.date = current_date.getFullYear() + '年' + this.zeroPadding((current_date.getMonth()+1), 2) + '月' + this.zeroPadding(current_date.getDate(), 2) + '日' + this.week[current_date.getDay()]
      
    },
    zeroPadding: function(num, len) {
      let zero = '';
      // 0の文字列を作成
      for(var i = 0; i < len; i++) {
        zero += '0';
      }
      // zeroの文字列と、数字を結合し、後ろ２文字を返す
      return (zero + num).slice(-len);
    },
    /**
     * 新しいタブ(東芝)
     */
    toshibaLink: function() {
        window.open(this.tochibaLinkInfo.url, "_self");
    },
    /**
     * 新しいタブ(東芝ESS)
     */
    essLink: function() {
      window.open(this.essLinkInfo.url, "_self");
    },
    /**
     * 新しいタブ(お問い合わせ)
     */
    contactNewWindow: function(){
      window.open("./contact", "_self");
    },
    /**
     * ログアウト
     */
    loginNewWindow: async function(){
      try {
        await service.logout();
        localStorage.clear()
        window.location.href = './';
      } catch (e) {
        console.error(e);
      }
    },
    handleResize: function() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    /**
     * ログアウト
     */
    async methodLogout (){
      try {
        const data = service.logout();
        window.location.href = data;
      } catch (e) {
        this.$dialogError('ログアウトに失敗しました。');
      }
    }
  },
  /**
   * メモリー増加の防止
   */
  beforeDestroy:function() {
    window.removeEventListener("resize", this.handleResize);
    this.width = null
    this.height = null
    this.items = null
    this.raName = null
    this.progressBox = null
    this.essLinkInfo = null
    this.tochibaLinkInfo = null
  },
  /**
   * storeの変更を監視
   */
  // watch:{
  //   progressBox: function() {
  //     if (store.progressBox[0].type == "logout") {
  //       boxProgressLogout(this,this.$router);
  //     }
  //   },
  // }
}

/**
 * ログアウトScreenBox
 */
// function logoutBox(){
//   mutations.progressBox("ログアウト",false)
// }
// function boxProgressLogout(e,router){ //e : Vue Object
//   router.push("/")
// }
</script>

<style lang="scss" scoped>
/* vpp.scssから START */
.vpp-g-header {
  width: 100%;
  height: 64px;
  background: #000;
  color: #fff !important;
}

.vpp-g-header.vpp-color-1 {
  background: linear-gradient(to right, #2f3600 0%, #000000 50%, #003e61 100%);
}

.vpp-g-header.vpp-color-2 {
  background: linear-gradient(to right, #003e61 0%, #000000 50%, #020036 100%);
}

.vpp-g-header.vpp-color-3 {
  background: linear-gradient(to right, #020036 0%, #000000 50%, #002b15 100%);
}

.vpp-g-header.vpp-color-4 {
  background: linear-gradient(to right, #002b15 0%, #000000 50%, #2f3600 100%);
}

.topContent {
  height: 60px;
  padding: 5px;
  position: sticky;
}


@media only screen and (max-width: 1450px) {
  #clock {
    display: none !important;
  }
}
.vpp-g-header ::v-deep .v-toolbar__content, .v-toolbar__extension {
  padding-left:0px;
}
.vpp-g-header ::v-deep .v-toolbar__title:hover {
  span{
    text-decoration: underline;
  }
}

/* vpp.scssから END */

.title_bar{
  background-color:transparent!important;
  color:white!important;  
}
.vpp-g-header { width: 100%; height: 64px; background: #000; color: #fff!important; }

.vpp-g-header.vpp-color-1 { background: linear-gradient(to right, #2f3600 0%, #000000 50%, #003e61 100%); }

.vpp-g-header.vpp-color-2 { background: linear-gradient(to right, #003e61 0%, #000000 50%, #020036 100%); }

.vpp-g-header.vpp-color-3 { background: linear-gradient(to right, #020036 0%, #000000 50%, #002b15 100%); }

.vpp-g-header.vpp-color-4 { background: linear-gradient(to right, #002b15 0%, #000000 50%, #2f3600 100%); }

.topContent {
  height: 60px;
  padding: 5px;
  position: sticky;
}

@media only screen and (max-width: 1450px) {
  #clock {
    display: none!important;
  }
}

.vpp-g-header ::v-deep .v-toolbar__content > .container {
  width: 100%;
  padding: 12px;
  margin-right: 0px;
  margin-left: 12px;
  margin-bottom: 3px;
}

</style>
