<template>

  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    offset-overflow
    :nudge-top="24"
  >
    <!-- text-field -->
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="disp"
        :label="label"
        append-icon="mdi-clock-outline"
        readonly
        :outlined="outlined"
        :dense="dense"
        :rules="rules"
        :clearable="clearable"
        v-bind="attrs"
        v-on="on"
        @click:append="menu = true"
        @click:clear="clear"
      ></v-text-field>
    </template>

    <!-- picker -->
    <v-card>
      <v-card-text class="d-flex">
          <div class="d-flex-inline mr-3">
            <h3>開始時刻:</h3>
            <v-time-picker
              v-model="startValue"
              :min="min"
              :max="endValue"
              scrollable
              ref="startPicker"
            ></v-time-picker>
          </div>
          <div class="d-flex-inline">
            <h3>終了時刻:</h3>
            <v-time-picker
              v-model="endValue"
              :min="startValue"
              :max="max"
              scrollable
              ref="endPicker"
            ></v-time-picker>
          </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          text
          color="primary"
          @click="menu = false"
          class="mr-3"
        >
          CANCEL
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          @click="save()"
          class="mr-3"
        >
          設定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>

export default {
  name: 'TimeRangeTextField',
  inheritAttrs: false,
  props: {
    start: {
      type: String,
    },
    end: {
      type: String,
    },
    label: {
      type: String,
    },
    dense: {
      type: Boolean
    },
    outlined: {
      type: Boolean
    },
    rules: {
      tyle: Array
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    clearable: {
      type: Boolean,
    }
  },
  data() {
    return {
      menu: false,
      startValue: '',
      endValue: '',
    };
  },
  computed: {
    disp () {
      if ( this.isEmpty(this.startValue) && this.isEmpty(this.endValue) ) {
        return '';
      } else {
        return (this.startValue||'' ) + ' ~ ' + (this.endValue||'');
      }
    }
  },
  watch: {
    start () {
      if ( this.isEmpty(this.start) ) this.startValue = '';
      else this.startValue = this.start;
    },
    end () {
      if ( this.isEmpty(this.end) ) this.endValue = '';
      else this.endValue = this.end;
    },
    menu () {
      if ( this.menu ) {
        // ポップアップ時点で時間を選択状態にする
        this.$nextTick( ()=> {
          this.$refs.startPicker.selecting = 1;
          this.$refs.endPicker.selecting = 1;
        });
      }
    }
  },
  created () {
    if ( this.isEmpty(this.start) ) this.startValue = '';
    else this.startValue = this.start;
    if ( this.isEmpty(this.end) ) this.endValue = '';
    else this.endValue = this.end;
  },
  methods: {
    save () {
      this.menu = false;
      this.$emit('update:start', this.startValue);
      this.$emit('update:end',this.endValue);
    },
    clear () {
      this.endValue = '';
      this.startValue = '';
      this.$emit('update:start', this.startValue);
      this.$emit('update:end',this.endValue);
      this.menu = false;
    },
    isEmpty (v) {
      return v === null || v === '' || v === undefined;
    }
  },
};
</script>