import api from './api';

const service = {
  /**
   * 取引先検索
   * @param {*} aggregator_id 
   * @param {*} area_id 
   * @param {*} params 
   * @returns 
   */
  async searchCustomers(aggregator_id, area_id, params) {
    return await api.get(`/aggregators/${aggregator_id}/area/${area_id}/negotiation_trading_partner/sale_plan`, { params });
  },

  /**
   * 販売計画（メール通知）ダウンロード
   * @param {*} aggregator_id 
   * @param {*} area_id 
   * @param {*} customer_code 
   * @param {*} params target_date(受け渡し日) , file_name(ファイル名) 
   * @returns 
   */
  async downloadSalePlanForNotification(aggregator_id, area_id, customer_code, params) {
    return await api.get(`/files/csv/aggregators/${aggregator_id}/area_id/${area_id}/negotiation_trading_partner/${customer_code}/sale_plan`, { params, responseType: 'blob', dataType: 'binary' });
  }
}

export default service;