import api from './api';

const service = {
  async searchHistoryExecuteFlg() {
    return await api.get(`/report_request_forecast`);
  },
  async updateHistoryExecuteFlg(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/report_request_forecast`, payload);
  },
}

export default service;
