import api from './api';

const service = {
  async searchSite(params, item) {
    // データ取得
    let data = await api.get(`/aggregators/${item.aggregator_id}/consignment-resource-status/pplants/${params.pplant_id}?bgroup_id=${item.bgroup_id}&target_date=${item.target_date}`, { params });
    const targetData = data.data[0];
    const current = targetData.last_time_code_type;
    // 発電所一覧
    const pplants = targetData.pplant_list;
    
    var eclimit = null;
    var iulimit = null;
    if(item != undefined){
      // 発電量上限値固定
      if(item.electric_upper_limit != undefined){
        eclimit = item.electric_upper_limit;
      } 
      // インバランス上限値固定
      if(item.imbalance_upper_limit != undefined){
        iulimit = item.imbalance_upper_limit;
      }
    }

    return { data: { list: targetData, current, pplants, eclimit, iulimit } };
  },
  async searchSite1Min(params, pp_id) {
    // データ取得
    let data = await api.get(`/aggregators/${params.aggregator_id}/consignment-resource-status/pplants-1min/${pp_id}/`, { params });
    return data.data[0];
  },
  // リスト取得 (発電所)
  async getSite(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/self_consignment_pplant_list/?area_id=${params.energy_area_id}&bgroup_id=${params.bgroup_id}`, { params });
  },
}

export default service;
