import { createGetters, createMutations } from '@/store/util';
import { USER_TYPE } from '@/constants';

const state = {
  // coginito情報
  auth_user_id: '',
  auth_user_name: '',
  auth_user_type: '',
  auth_role: '',
  auth_aggregator_id: '',
  auth_aggregator_name: '',
  auth_area_id: '',
  auth_workflow: '',
  auth_site: '',
  auth_bgroup_id: '',
  // メニュー情報
  auth_menuJson: {},
  // システム管理者かどうか
  auth_is_sv: false,
  // アグリゲーターかどうか
  auth_is_ag: false,
  // アセットオーナーかどうか
  auth_is_as: false,
};

const getters = {
  ...createGetters(state),
};

const mutations = {
  ...createMutations(state)
}

const actions = {
  fetch(ctx) {
    // login画面でlocalStorageにセットした情報を復元する
    const userid = localStorage.getItem('userid');
    const username = localStorage.getItem('username');
    const usertype = localStorage.getItem('usertype');
    const role = localStorage.getItem('role');
    const aggregatorId = localStorage.getItem('aggregatorId');
    const aggregatorName = localStorage.getItem('aggregatorName');
    const areaId = localStorage.getItem('areaId');
    const workflow = localStorage.getItem('workflow');
    const site = localStorage.getItem('site');
    const bgroupId = localStorage.getItem('bgroupId');
    const menuJson = JSON.parse(localStorage.getItem('menuJson'));

    ctx.commit('auth_user_id', userid);
    ctx.commit('auth_user_name', username);
    ctx.commit('auth_user_type', usertype);
    ctx.commit('auth_role', role);
    ctx.commit('auth_aggregator_id', aggregatorId);
    ctx.commit('auth_aggregator_name', aggregatorName);
    ctx.commit('auth_area_id', areaId);
    ctx.commit('auth_workflow', workflow);
    ctx.commit('auth_site', site);
    ctx.commit('auth_bgroup_id', bgroupId);
    ctx.commit('auth_menuJson', menuJson);

    // システム管理者かどうか
    if (usertype === USER_TYPE.TOSHIBA) {
      ctx.commit('auth_is_sv', true);
    }
    // アグリゲーターかどうか
    if (usertype === USER_TYPE.AGGREGATOR) {
      ctx.commit('auth_is_ag', true);
    }
    // アセットオーナーかどうか
    if (usertype === USER_TYPE.ASSETSOWNER) {
      ctx.commit('auth_is_as', true);
    }
    if (usertype === USER_TYPE.OUTSOURCE) {
      ctx.commit('auth_is_sv', true);
    }

  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};