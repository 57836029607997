import api from './api';

const service = {
  async uploadSupplyAvailableAmount(params, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/${params.aggregator_id}/eprx_supply_available_amount`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async templateSupplyAvailableAmount() {
    return await api.get(`/files/csv/eprx_supply_available_amount/templete`, { responseType: 'blob', dataType: 'binary' });
  },
  async uploadSupplyCompositeProducts(params, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/${params.aggregator_id}/eprx_supply_composite_products`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async templateSupplyCompositeProducts() {
    return await api.get(`/files/csv/eprx_supply_composite_products/templete`, { responseType: 'blob', dataType: 'binary' });
  },
  async selectPplantConfig(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/eprx_pplant_config/${params.pplant_id}`);
  },
  async updatePplantConfig(params, payload) {
    return await api.post(`/aggregators/${params.aggregator_id}/eprx_pplant_config/${params.pplant_id}`, payload);
  },
}

export default service;