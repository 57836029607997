<template>
  <div style="font-family:gothic">
    <v-footer class="pa-3" fixed app clipped-left style="background:white!important;">
      <p tabindex="6" style="margin:0px; cursor:pointer; font-size:10.5px; font-weight:bold; margin-left: 30px; text-decoration:underline" @click="essLink" @keydown.enter="essLink">{{ essText }}</p>
      <p tabindex="7" style="margin:0px; cursor:pointer; font-size:10.5px; font-weight:bold; margin-left: 30px; text-decoration:underline" @click="privacyLink" @keydown.enter="privacyLink">{{ privacyText }}</p>
          <v-spacer/>
      <p tabindex="8" style="margin:0px; cursor:pointer; font-size:10px; font-weight:bold" @click="copyrightLink" @keydown.enter="copyrightLink">{{ copyrightText }}</p>
    </v-footer>
    <!-- <v-footer class="pa-3" dark fixed app clipped-left>
      <p style="margin:0px; cursor:pointer; font-size:10.5px; font-weight:bold; padding-left: 30px; text-decoration:underline" @click="essLink">{{ essText }}</p>
      <p style="margin:0px; cursor:pointer; font-size:10.5px; font-weight:bold; padding-left: 30px; text-decoration:underline" @click="privacyLink">{{ privacyText }}</p>
          <v-spacer/>
      <p style="margin:0px; cursor:pointer; font-size:10px; font-weight:bold" @click="copyrightLink">{{ copyrightText }}</p>
    </v-footer> -->
</div>
</template>

<script>
"use strict";
import config from "@/config"
export default {
  name: 'LoginFooter',
  data: () => ({
    essText: config.link.ess.contents.data,
    privacyText: config.link.privacy.contents.data,
    copyrightText: config.link.copyright.contents.data
  }),
  methods: {
    /**
     * 個人情報保護方針を新たな窓でオープン
     */
    essLink: function(){
      window.open(config.link.ess.url, '_self');
    },
    /**
     * 個人情報保護方針を新たな窓でオープン
     */
    privacyLink: function(){
      window.open(config.link.privacy.url, '_self');
    },
    /**
     * Copyrightを新たな窓でオープン
     */
    copyrightLink: function(){
      window.open(config.link.copyright.url, '_self');
    }
  },
  beforeDestroy: function(){
    this.name = null
    this.privacyText = null
    this.copyrightText = null
  }
}
</script>