import api from './api';

const service = {
    /**
     * 需給調整市場・JEPX容量配分 ダウンロード
     * @param {*} aggregator_id 
     * @param {Object} params 
     * @param {number} params.area_id 
     * @param {number} params.bgroup_id 
     * @param {number} params.pplant_id 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getAlgorithmResult(aggregator_id, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/algorithm_result/market_allocation`, { params, responseType:'blob', dataType:'binary' });
    },

    /**
     * 三次②入札量 ダウンロード
     * @param {*} aggregator_id 
     * @param {Object} params 
     * @param {number} params.area_id 
     * @param {number} params.bgroup_id 
     * @param {number} params.pplant_id 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getEprxTertiary2Bid(aggregator_id, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/algorithm_result/eprx-tertiary2-bid`, { params, responseType:'blob', dataType:'binary' });
    },
    /**
     * 週間市場・入札計画 ダウンロード
     * @param {*} aggregator_id 
     * @param {Object} params 
     * @param {number} params.area_id 
     * @param {number} params.bgroup_id 
     * @param {number} params.pplant_id 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getWeeklyMarketBidPlan(aggregator_id, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/algorithm_result/weekly_market/bid_plan`, { params, responseType:'blob', dataType:'binary' });
    },
    /**
     * 週間商品蓄電池計画アルゴリズム ダウンロード
     * @param {*} aggregator_id 
     * @param {Object} params 
     * @param {number} params.area_id 
     * @param {number} params.bgroup_id 
     * @param {number} params.pplant_id 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getWeeklyMarketBatteryPlan(aggregator_id, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/algorithm_result/weekly_market/battery_plan`, { params, responseType:'blob', dataType:'binary' });
    },
}

export default service;