/**
 * stateからgettersを生成する
 * const state = { hoge: null };
 * の場合
 * getters.hogeを生成する
 */
export const createGetters = (state, getters) => {
  getters = getters || {};
  Object.keys(state).forEach( key => {
    if (getters[key] === undefined)
      getters[key] = state => {
        console.debug('getters', key, state[key]);
        return state[key];
      }
  });
  return getters;
}

/**
 * stateからmutationsを生成する
 * const state = { hoge: null };
 * の場合 mutationのメソッド'hoge'、'reset_hoge'を生成する
 */
export const createMutations = (state, mutations) => {
  mutations = mutations || {};
  const initial = JSON.parse(JSON.stringify(state));
  Object.keys(state).forEach( key => {
    
    const method = key;
    if (mutations[method] === undefined)
      mutations[method] = ( state, value ) => {
        state[key] = value;
        console.debug('mutation '+ key, state[key]);
      }
    if (mutations['reset_' + method] === undefined) {
      mutations['reset_' + method] = state => {
        state[key] = initial[key];
        console.debug('mutation reset_'+ key);
      }
    }
  });
  return mutations;
}