import api from './api';

const service = {
  async uploadForecast(resource_type, kind, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/simulation/forecast/upload/resource_types/${resource_type}/${kind}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async createTempleteForecast() {
    return await api.get(`/files/templates/simulation/forecast`, { params: {} });
  },

  async uploadImbalance(file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/analysis_report/imbalance`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  // 発電所検索処理
  async searchPplant(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/simulation_register_pplant_list/`, { params });
  },
  // 出力抑制情報アップロード
  async uploadOutputSuppression(params, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/${params.aggregator_id}/${params.pplant_id}/simulation_output_suppression`, form,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }, 
  // 出力抑制情報テンプレート取得
  async createTempleteOutputSuppression(params) {
    return await api.get(`/files/templates/simulation_output_suppression`, { params, responseType: 'blob', dataType: 'binary' });
  },
}

export default service;