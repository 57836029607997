import api from './api';

const service = {
  async searchContractCondition(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/contract/${params.contract_id}/condition/`, { params: { area_id: params.area_id } });
  },
  async createContractCondition(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/contract/${payload.contract_id}/condition/`, payload);
  },
  async updateContractCondition(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/contract/${payload.contract_id}/condition/${payload.contract_condition_id}`, payload);
  },
  async deleteContractCondition(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/contract/${payload.contract_id}/condition/${payload.contract_condition_id}`);
  },
}

export default service;
