import axios from 'axios';

const baseURL = 'api';

// 設定
const api = axios.create({
  baseURL,
  // settings
});

// リクエスト前処理
api.interceptors.request.use( request => {
  console.log('api request', request);
  return request
});

// レスポンス前処理
api.interceptors.response.use( response => {
  console.log('api response', response);
  // セッションチェック
  // if( (Object.prototype.toString.call(response.data).slice(8, -1).toLowerCase() == 'string' && response.data.startsWith("<!DOCTYPE")) ){
  //   console.log('session invalid');
  //   localStorage.clear();
  //   window.location.href = "/";
  // }
  return response;
});

export default api;
