import api from './api';

const service = {

  // アグリゲータ照会
  async findMarketAggregator(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/market_trading_config/area`);
  },
  // 手数料 更新
  async updateFee(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/market_trading_config/area/commission`, params);
  },
  // スポット市場売買上下限デフォルト値照会
  async findSpotMarketTradingThresholdDefault(aggregator_id, area_id) {
    return await api.get(`/aggregators/${aggregator_id}/areas/${area_id}/market_trading_config/area/spot_market/trading/threshold/default`);
  },
  // スポット市場売買上下限デフォルト値 更新
  async updateUserSpotMarketDefault(params, aggregator_id, area_id) {
    return await api.put(`/aggregators/${aggregator_id}/areas/${area_id}/market_trading_config/area/spot_market/trading/threshold/default`, params);
  },
  // 時間前市場売買上下限デフォルト値照会
  async findSameDayMarketTradingThresholdDefault(aggregator_id, area_id) {
    return await api.get(`/aggregators/${aggregator_id}/areas/${area_id}/market_trading_config/area/same_day_market/trading/threshold/default`);
  },
  // 時間前市場売買上下限デフォルト値 更新
  async updateUserSameDayMarketDefault(params, aggregator_id, area_id) {
    return await api.put(`/aggregators/${aggregator_id}/areas/${area_id}/market_trading_config/area/same_day_market/trading/threshold/default`, params);
  },
  // スポット市場入札価格照会
  async findSpotMarketBidPrice(aggregator_id, area_id) {
    return await api.get(`/aggregators/${aggregator_id}/areas/${area_id}/market_trading_config/area/spot_market/bid_price`);
  },
  // スポット市場入札価格 更新
  async updateUserSpotMarketBidPrice(params, aggregator_id, area_id) {
    return await api.put(`/aggregators/${aggregator_id}/areas/${area_id}/market_trading_config/area/spot_market/bid_price`, params);
  },
  // 時間前市場 アイスバーグ注文量 照会
  async searchSameDayMarketIcebergOrderQuantity(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/market_trading_config/area/same_day_market/iceberg_order_quantity`);
  },
  // 時間前市場 アイスバーグ注文量 登録
  async updateSameDayMarketIcebergOrderQuantity(aggregator_id, params) {
    return await api.put(`/aggregators/${aggregator_id}/market_trading_config/area/same_day_market/iceberg_order_quantity`, params);
  },
  // スポット市場売買上下限デフォルト値照会（発電所別）
  async findSpotMarketTradingThresholdDefaultPplant(aggregator_id, area_id, pplant_id) {
    return await api.get(`/aggregators/${aggregator_id}/areas/${area_id}/pplants/${pplant_id}/market_trading_config/pplant/spot_market/trading/threshold/default`);
  },
  // スポット市場売買上下限デフォルト値 更新（発電所別）
  async updateUserSpotMarketDefaultPplant(params, aggregator_id, area_id, pplant_id) {
    return await api.put(`/aggregators/${aggregator_id}/areas/${area_id}/pplants/${pplant_id}/market_trading_config/pplant/spot_market/trading/threshold/default`, params);
  },
}

export default service;