<template>
   <div class="text-xs-center">
      <v-dialog
        v-model="show"
        persistent width="400"
        :overlay-opacity="0.2"
      >
        <v-card >
          <v-card-text>
            <span>{{text}}</span>
            <v-progress-linear indeterminate color="#9FDEFF" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Progress',
  data: () => ({
  }),
  computed: {
    ...mapState('progress', [
      'show',
      'text',
      'param'
    ] ),
  },
};
</script>