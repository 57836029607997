import api from './api';

const service = {
    async searchBGroupList(aggregator_id, params) {
        return await api.get(`/aggregators/${aggregator_id}/self_consignment/bgroup_pplant/`, { params:{area_id:params.area_id,target_date:params.targetDate} });
    },
    async searchForecastDataByArea(aggregator_id, area_id, targetDate) {
        return await api.get(`/aggregators/${aggregator_id}/self_consignment/areas/${area_id}/dates/${targetDate}/electric_power/forecast_pv`);
    },
    async searchForecastDataByBGroup(aggregator_id, bgroup_id, targetDate) {
        return await api.get(`/aggregators/${aggregator_id}/self_consignment/balancing_groups/${bgroup_id}/dates/${targetDate}/electric_power/forecast_pv`);
    },
    async searchForecastDataByPPlant(aggregator_id, pplant_id, targetDate) {
        return await api.get(`/aggregators/${aggregator_id}/self_consignment/power_plants/${pplant_id}/dates/${targetDate}/electric_power/forecast_pv`);
    },
}

export default service;
