export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
}

// 権限（管理者:administrator、運用者:operator、照会者:referrer）
export const ROLE = {
  ADMIN: 'administrator',
  OPERATOR: 'operator',
  REFERRER: 'referrer'
};

// ユーザー種別（アグリゲータ:aggregator、アセットオーナー:owner、東芝:toshiba）
export const USER_TYPE = {
  AGGREGATOR: 'aggregator',
  ASSETSOWNER: 'owner',
  GENERATOR: 'generator',
  CALCULATE: 'calculate',
  OUTSOURCE: 'outsource',
  TOSHIBA: 'toshiba',
};

// ワークフロー権限（申請者、承認者）
export const WORKFLOW = {
  APPLICANT: 'applicant',
  APPROVER: 'approver',
};

// リソースタイプ 1:PV,2:風力,3:バイオマス,4:バイオガス,5:水力,6:蓄電池,7:火力
export const RESOURCE_TYPE = {
  PV : '1',
  WP : '2',
  BM : '3',
  BG : '4',
  HP : '5',
  BT : '6',
  FP : '7'
};

// 連携方法 1:TNAS連携、2:東芝IoT GW、3:ラプラスシステム連携、4:その他、5:九州電力連携、6:(特高・高圧)NSC連携、7:(低圧)NSC連携
export const DATA_COOPERATE_TYPE = {
  TNAS: '1',
  IOTGW: '2',
  LAPLACE: '3',
  OTHER: '4',
  KYUSYU:'5',
  NSC_HIGH:'6',
  NSC_LOW:'7',
  ELWA:'8',
  MQTTGW:'9',
  MQTTSFC:'10',
};

// 蓄電池運転モード
export const BATTERY_OPERATION_MODE_TYPE = {
  A: '1',   //併設型タイムシフト（22年度版）
  B: '2',   //GC前インバラ回避（インバラ料金最小）
  C: '3',   //手動運転
  D: '4',   //GC後インバラ回避
  E: '5',   //マルチユース
  F: '6',   //BGタイムシフト（22年度版）
  G: '7',   //蓄電所タイムシフト
  H: '8',   //アービトラージタイムシフト（22年度版）
  I: '9',   //GC前インバラ回避（インバラ絶対値最小）
  J: '10',  //マルチユース（卸売＆三次②）23年度版
  K: '11',  //併設型タイムシフト
  L: '12',  //BGタイムシフト
  M: '13',  //アービトラージタイムシフト
  O: '14',  //マルチユース（卸売＆需給調整）24A版
};

// 蓄電池運転種別
export const BATTERY_OPERATION_KIND = {
  TIMESHIFT: '1',   // タイムシフト
  IMBALANCE: '2',   // インバランス回避
  MALTIUSE:  '3',   // マルチユース
  ARBITRAGE: '4',   // アービトラージ
  OTHER:     '5',   // その他
};

// 連携方法 1:TNAS連携、2:NSC連携、3:MQTT版SFC連携、4:WebAPI、5:CSVアップロード
export const DEMAND_DATA_COOPERATE_TYPE = {
  TNAS:'1',
  NSC_HIGH:'2',
  NSC_LOW:'3',
  MQTTSFC:'4',
  WEBAPI:'5',
  CSVUP:'6',
};

// グラフの色設定
export const COLORS = {
  ORDER: [
    { STD: '#1E88E5', THIN: '#90CAF9' },//blue 
    { STD: '#FB8C00', THIN: '#FFCC80' },//orange 
    { STD: '#43A047', THIN: '#A5D6A7' },//green 
    { STD: '#E53935', THIN: '#EF9A9A' },//red
    { STD: '#8E24AA', THIN: '#CE93D8' },//purple 
  ],
  RESOURCES: {
    HP: { STD: '#1E88E5', THIN: '#90CAF9' },//blue 
    PV: { STD: '#FB8C00', THIN: '#FFCC80' },//orange 
    WP: { STD: '#43A047', THIN: '#A5D6A7' },//green 
    BG: { STD: '#E53935', THIN: '#EF9A9A' },//red
    BM: { STD: '#8E24AA', THIN: '#CE93D8' },//purple 
    BT: { STD:'#C0CA33', THIN: '#E6EE9C' }, //lime
    IB: { STD:'#FDD835', THIN: '#FFF59D' }, //yellow
  },
  PLAN: { STD:'#FDD835', THIN: '' },//yellow
  PROCUREMENT: { STD:'#00ACC1', THIN: '#80DEEA' },//cyan
  IMBALANCE: { STD: '#1E88E5', THIN: '#90CAF9' },//blue 
  IMBALANCE_ALERT: { STD: '#E53935', THIN: '#EF9A9A' },//red
  SPOTPRICE: { STD: '#546E7A', THIN: '#B0BEC5' },// blue-grey
  SOC: { STD: '#8b4513', THIN: '#d2b48c' },// brown
  IMBALANCES: {
    IMBALANCE_SURPLUS_QUANTITY: { STD: '#FB8C00', THIN: '#FFCC80' },  // 余剰インバランス量
    IMBALANCE_SHORTAGE_QUANTITY: { STD: '#1E88E5', THIN: '#90CAF9' }, // 不足インバランス量
    IMBALANCE_SURPLUS_PRICE: { STD: '#43A047', THIN: '#A5D6A7' },  // 余剰インバランス価格
    IMBALANCE_SHORTAGE_PRICE: { STD: '#8b4513', THIN: '#d2b48c' }, // 不足インバランス価格
    JEPX: { STD: '#8E24AA', THIN: '#CE93D8' },               // JEPX
    IMBALANCE_PRICE: { STD: '#546E7A', THIN: '#B0BEC5' },    // インバランス料金
    ALERT_HIGH: { STD: '#E53935', THIN: '#EF9A9A' },         // アラート上限閾値
    ALERT_LOW: { STD:'#FDD835', THIN: '#FFF59D' },           // アラート下限閾値
  },
  OUTPUT_CONTROL: { STD: '#E53935', THIN: '#EF9A9A' },// 出力制御値
}

// 入札ステータス
export const BID_STATUS_TYPE = {
  MAKING: '1', // 1:作成中
  PENDING: '2', // 2:承認待ち
  BIDDING: '3', // 3:入札中
  PARTCONTRACT: '4', // 4:一部約定
  CONTRACTED: '5', // 5:約定済み
  UNSUCCESSFUL: '6', // 6:不落札
  PARTIAL: '7', // 7:一部不落札
  FAILURE: '8', // 8:入札失敗
  CLOSE: '9', // 9:受付終了
}

// ワークフロー状況
export const WORKFLOW_STATUS_TYPE = {
  BEFORE_DRAFT: '1',// 1:起草前
  AFTER_DRAFT: '2',// 2:起草後
  APPROVED: '3' // 3:承認済
}

// シミュレーション コンソーシアム
export const SIMULATION_CONSORTIUM = {
  NAME: 'コンソーシアム',
  VALUE: 'consortium'
}

// 自動取引方法（翌日スポット市場取引、翌日時間前市場取引、当日時間前市場取引）
export const TRADING_TYPE = {
  SPOTAFTER : '1',
  SAMEDAYAFTER : '2',
  SAMEDAY : '3',
  SPOTAFTERNEGOTIATION : '4',
  SAMEDAYBID : '5',
  SPOTAFTERPPLANT : '6',
};

// スポット市場取引計画 1:東芝アルゴリズム（22年度版戦略取引）2:簡易アルゴリズム（予測値積み上げ）0:実行しない3:東芝アルゴリズム（23年度版戦略取引）
export const SPOT_TRADING_TYPE = {
  NOT: '0',
  TOSHIBA: '1',
  SIMPLE: '2',
  TOSHIBA_23: '3',
};

// 時間前市場取引計画 1:東芝アルゴリズム（22年度版戦略取引）2:21年度アルゴリズム（予測－計画）0:実行しない3:東芝アルゴリズム（23年度版戦略取引）
export const SAMEDAY_TRADING_TYPE = {
  NOT: '0',
  TOSHIBA: '1',
  OLD: '2',
  TOSHIBA_23: '3',
};

// 取引計画作成周期 1:30分周期で作成2:1日1回作成（8:00）
export const SAMEDAY_CYCLE_TYPE = {
  THIRTY_MIN: '1',
  ONE_DAY: '2',
};

// スポット入札方式
export const SPOT_BID_MODE = {
  SPOT_BID_AREA: '1', // スポット市場取引（エリア別）
  SPOT_BID_AREA_PPLANT: '2', // スポット市場取引（エリア、発電所別）
};

export default {
  ROLE,
  USER_TYPE,
  WORKFLOW,
  RESOURCE_TYPE,
  DATA_COOPERATE_TYPE,
  COLORS,
  BID_STATUS_TYPE,
  WORKFLOW_STATUS_TYPE,
  SIMULATION_CONSORTIUM,
  TRADING_TYPE,
  SPOT_TRADING_TYPE,
  BATTERY_OPERATION_KIND,
  SPOT_BID_MODE,
}