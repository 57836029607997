import api from './api';

const service = {
  async uploadPriceCalucatePplant(aggregator_id, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/settlement/${aggregator_id}/pplant`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async createTemplatePplant(aggregator_id) {
    return await api.get(`/files/templates/settlement/${aggregator_id}/pplant`, { responseType: 'blob', dataType: 'binary' });
  },

  async uploadPriceCalucateRetail(aggregator_id, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/settlement/${aggregator_id}/retail`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async createTemplateRetail(aggregator_id) {
    return await api.get(`/files/templates/settlement/${aggregator_id}/retail`, { responseType: 'blob', dataType: 'binary' });
  },

  async uploadPriceCalucateRetailPartner(aggregator_id, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/settlement/${aggregator_id}/retail_partner`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async createTemplateRetailPartner(aggregator_id) {
    return await api.get(`/files/templates/settlement/${aggregator_id}/retail_partner`, { responseType: 'blob', dataType: 'binary' });
  },

  async uploadPriceCalucateSaas(aggregator_id, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/settlement/${aggregator_id}/saas`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async createTemplateSaas(aggregator_id) {
    return await api.get(`/files/templates/settlement/${aggregator_id}/saas`, { responseType: 'blob', dataType: 'binary' });
  },
  async uploadPriceCalucatePplantResult(file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/settlement/pplant_result`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  async uploadPriceCalucateOutsource(aggregator_id, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/settlement/${aggregator_id}/outsource`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async createTemplateOutsource(aggregator_id) {
    return await api.get(`/files/templates/settlement/${aggregator_id}/outsource`, { responseType: 'blob', dataType: 'binary' });
  },
  async uploadPriceCalucatePplantResultNscLow(file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/settlement/pplant_result/nsc_low`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

}

export default service;