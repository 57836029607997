<template>
  <v-dialog
    v-model="show"
    :max-width="500"
    :overlay-opacity="0.2"
    persistent
  >
    <v-card>
      <v-card-text class="pa-4">
        <v-alert
          outlined
          text
          :type="type"
        >
          <div v-for="(text, index) in message.split('\n')" :key="index">
            {{ text }}
          </div>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="primary"
          outlined
          text
          @click="ok"
        >
          OK
        </v-btn>
        <v-btn
          depressed
          color="primary"
          outlined
          text
          @click="cancel"
        >
          キャンセル
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import programmatic from '@/mixins/programmatic';

export default {
  mixins: [programmatic],
  props: {
    type: {
      type: String,
      default: 'warning',
    },
    message: {
      type: String,
      required: true,
    },
    okAction: {
      type: Function,
      required: true,
    },
    cancelAction: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ok() {
      this.okAction();
      this.close();
    },
    cancel() {
      this.cancelAction();
      this.close();
    },
  },
};
</script>