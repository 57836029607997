import api from './api';

const service = {
  async searchSite(params, item) {
    return await api.get(`/aggregators/${params.aggregator_id}/battery-output-control/pplants/${params.pplant_id}`, { params: {target_date: item.target_date} });
  },
  // リスト取得 (発電所)
  async getSite(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/battery-output-control/pplants`, { params });
  },
}

export default service;
