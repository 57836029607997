import api from './api';
import { deepClone } from '@/util';

export default {
  async request(params, items) {
    let payload = deepClone(params);
    payload.resource_list = deepClone(items);

    return await api.post(`/aggregators/${params.aggregator_id}/resource_types/${params.resource_type}/simulation_request_forecast`, payload);
  },
  async searchResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/simulation_request/resource`, { params });
  }
}