import api from './api';
import { deepClone } from '@/util';

const timeLabels = [
  { time_code_type: '1', time: '0:00 - 0:30' },
  { time_code_type: '2', time: '0:30 - 1:00' },
  { time_code_type: '3', time: '1:00 - 1:30' },
  { time_code_type: '4', time: '1:30 - 2:00' },
  { time_code_type: '5', time: '2:00 - 2:30' },
  { time_code_type: '6', time: '2:30 - 3:00' },
  { time_code_type: '7', time: '3:00 - 3:30' },
  { time_code_type: '8', time: '3:30 - 4:00' },
  { time_code_type: '9', time: '4:00 - 4:30' },
  { time_code_type: '10', time: '4:30 - 5:00' },
  { time_code_type: '11', time: '5:00 - 5:30' },
  { time_code_type: '12', time: '5:30 - 6:00' },
  { time_code_type: '13', time: '6:00 - 6:30' },
  { time_code_type: '14', time: '6:30 - 7:00' },
  { time_code_type: '15', time: '7:00 - 7:30' },
  { time_code_type: '16', time: '7:30 - 8:00' },
  { time_code_type: '17', time: '8:00 - 8:30' },
  { time_code_type: '18', time: '8:30 - 9:00' },
  { time_code_type: '19', time: '9:00 - 9:30' },
  { time_code_type: '20', time: '9:30 - 10:00' },
  { time_code_type: '21', time: '10:00 - 10:30' },
  { time_code_type: '22', time: '10:30 - 11:00' },
  { time_code_type: '23', time: '11:00 - 11:30' },
  { time_code_type: '24', time: '11:30 - 12:00' },
  { time_code_type: '25', time: '12:00 - 12:30' },
  { time_code_type: '26', time: '12:30 - 13:00' },
  { time_code_type: '27', time: '13:00 - 13:30' },
  { time_code_type: '28', time: '13:30 - 14:00' },
  { time_code_type: '29', time: '14:00 - 14:30' },
  { time_code_type: '30', time: '14:30 - 15:00' },
  { time_code_type: '31', time: '15:00 - 15:30' },
  { time_code_type: '32', time: '15:30 - 16:00' },
  { time_code_type: '33', time: '16:00 - 16:30' },
  { time_code_type: '34', time: '16:30 - 17:00' },
  { time_code_type: '35', time: '17:00 - 17:30' },
  { time_code_type: '36', time: '17:30 - 18:00' },
  { time_code_type: '37', time: '18:00 - 18:30' },
  { time_code_type: '38', time: '18:30 - 19:00' },
  { time_code_type: '39', time: '19:00 - 19:30' },
  { time_code_type: '40', time: '19:30 - 20:00' },
  { time_code_type: '41', time: '20:00 - 20:30' },
  { time_code_type: '42', time: '20:30 - 21:00' },
  { time_code_type: '43', time: '21:00 - 21:30' },
  { time_code_type: '44', time: '21:30 - 22:00' },
  { time_code_type: '45', time: '22:00 - 22:30' },
  { time_code_type: '46', time: '22:30 - 23:00' },
  { time_code_type: '47', time: '23:00 - 23:30' },
  { time_code_type: '48', time: '23:30 - 24:00' },
];

const service = {
  // 自動取引設定照会
  async search(params) {
    let rtn = await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/auto_trading`);

    let items = deepClone(rtn.data.spotAfter.workday.dataList);
    if (items.length != 0) {
      for (let  i = 0; i < timeLabels.length; i++) {
        items[i].time = timeLabels[i].time;
      }
    }
    rtn.data.spotAfter.workday.dataList = items;

    items = deepClone(rtn.data.spotAfter.holiday.dataList);
    if (items.length != 0) {
      for (let  i = 0; i < timeLabels.length; i++) {
        items[i].time = timeLabels[i].time;
      }
    }
    rtn.data.spotAfter.holiday.dataList = items;

    items = deepClone(rtn.data.spotAfterNegotiation.workday.dataList);
    if (items.length != 0) {
      for (let  i = 0; i < timeLabels.length; i++) {
        items[i].time = timeLabels[i].time;
      }
    }
    rtn.data.spotAfterNegotiation.workday.dataList = items;

    items = deepClone(rtn.data.spotAfterNegotiation.holiday.dataList);
    if (items.length != 0) {
      for (let  i = 0; i < timeLabels.length; i++) {
        items[i].time = timeLabels[i].time;
      }
    }
    rtn.data.spotAfterNegotiation.holiday.dataList = items;
    
    items = deepClone(rtn.data.sameDayAfter.workday.dataList);
    if (items.length != 0) {
      for (let  i = 0; i < timeLabels.length; i++) {
        items[i].time = timeLabels[i].time;
      }
    }
    rtn.data.sameDayAfter.workday.dataList = items;
    
    items = deepClone(rtn.data.sameDayAfter.holiday.dataList);
    if (items.length != 0) {
      for (let  i = 0; i < timeLabels.length; i++) {
        items[i].time = timeLabels[i].time;
      }
    }
    rtn.data.sameDayAfter.holiday.dataList = items;
    
    items = deepClone(rtn.data.sameDay.workday.dataList);
    if (items.length != 0) {
      for (let  i = 0; i < timeLabels.length; i++) {
        items[i].time = timeLabels[i].time;
      }
    }
    rtn.data.sameDay.workday.dataList = items;
    
    items = deepClone(rtn.data.sameDay.holiday.dataList);
    if (items.length != 0) {
      for (let  i = 0; i < timeLabels.length; i++) {
        items[i].time = timeLabels[i].time;
      }
    }
    rtn.data.sameDay.holiday.dataList = items;

    console.log(rtn);

    return rtn
  },
  // 自動取引設定更新
  async update(params, result) {

    let payload = deepClone(result)

    delete payload.aggregator_id;
    delete payload.area_id;

    delete payload.spotAfter.user_name;
    delete payload.sameDayAfter.user_name;
    delete payload.sameDay.user_name;

    console.log(payload);

    return await api.put(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/auto_trading`, payload );
  },
}

export default service;