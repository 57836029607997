import api from './api';

const service = {
  async searchResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/nsc_low_resources/stop`, { params });
  },
  async search(params) {
    const aggregator_id = params.aggregator_id;
    const nsc_generation_contractor_code = params.nsc_generation_contractor_code;
    const nsc_receiving_electricity_point_specific_no = params.nsc_receiving_electricity_point_specific_no;
    const queryParams = {
      target_date: params.target_date
    }
    return await api.get(`/aggregators/${aggregator_id}/nsc_low_resources/${nsc_generation_contractor_code}/${nsc_receiving_electricity_point_specific_no}/stop`, { params: queryParams })
  },
  async create(aggregator_id, nsc_generation_contractor_code, nsc_receiving_electricity_point_specific_no, payload) {
    return await api.post(`/aggregators/${aggregator_id}/nsc_low_resources/${nsc_generation_contractor_code}/${nsc_receiving_electricity_point_specific_no}/stop`, payload)
  },
  async update(aggregator_id, nsc_generation_contractor_code, nsc_receiving_electricity_point_specific_no, payload) {
    return await api.put(`/aggregators/${aggregator_id}/nsc_low_resources/${nsc_generation_contractor_code}/${nsc_receiving_electricity_point_specific_no}/stop`, payload)
  },
  async delete(aggregator_id, nsc_generation_contractor_code, nsc_receiving_electricity_point_specific_no, payload) {
    return await api.delete(`/aggregators/${aggregator_id}/nsc_low_resources/${nsc_generation_contractor_code}/${nsc_receiving_electricity_point_specific_no}/stop`, {
      data: payload
    });
  },
}

export default service;
