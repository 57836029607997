import api from './api';

const service = {
    async searchBatteryList(aggregator_id, params) {
        return await api.get(`/aggregators/${aggregator_id}/resources/batteries`, { params: { area_id: params.area_id, bgroup_id: params.bgroup_id } });
    },
    async downloadBatteryPlan(params) {
        return await api.get(`/files/csv/aggregators/${params.aggregator_id}/resources/batteries/operation/plan/minutes/${params.kind}`, { params });
    }
}

export default service;