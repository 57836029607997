import api from './api';

const service = {
  // 自己託送通知履歴 照会
  async getHistory(param) {
    return await api.get(`/aggregators/${param.aggregator_id}/self-consignment/send_history`, { params:{ area_id: param.area_id, jgroup_id: param.jgroup_id, dpoint_id: param.dpoint_id, start_target_date: param.start_target_date, end_target_date: param.end_target_date, send_date: param.send_date,  }});
  },
    // 自己託送通知履歴 ダウンロード
    async download(param) {
      return await api.get(`/aggregators/${param.aggregator_id}/self-consignment/send_history/download`, { params:{ area_id: param.area_id, jgroup_id: param.jgroup_id, dpoint_id: param.dpoint_id, target_date: param.target_date, file_name: param.file_name, }, responseType:'blob', dataType:'binary'});
    },
}

export default service;