import api from './api';

const service = {
    async searchJGroupList(aggregator_id, params) {
        return await api.get(`/aggregators/${aggregator_id}/jgroup_list/demmand_forecast`, { params });
    },
    async searchForecastDataByArea(aggregator_id, area_id, targetDate) {
        return await api.get(`/aggregators/${aggregator_id}/areas/${area_id}/dates/${targetDate}/demand/forecast`);
    },
    async searchForecastDataByJGroup(aggregator_id, jgroup_id, targetDate) {
        return await api.get(`/aggregators/${aggregator_id}/jgroups/${jgroup_id}/dates/${targetDate}/demand/forecast`);
    },
    async searchForecastDataByDpoint(aggregator_id, dpoint_id, targetDate) {
        return await api.get(`/aggregators/${aggregator_id}/dpoints/${dpoint_id}/dates/${targetDate}/demand/forecast`);
    },
}

export default service;
