<template>
  <v-dialog
    v-model="display"
    overlay-opacity="0.2"
    persistent
    scrollable
    v-bind="$attrs"
  >
    <v-card>
      <v-card-text class="pa-4">
        <slot>
          <v-alert
            outlined
            text
            :type="type"
          >
            <div v-for="(text, index) in message.split('\n')" :key="index">
              {{ text }}
            </div>
          </v-alert>
        </slot>
      </v-card-text>
      <v-card-actions>
        <slot name="action">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            outlined
            text
            @click="ok"
          >
            OK
          </v-btn>
          <v-btn
            depressed
            color="primary"
            outlined
            text
            @click="cancel"
          >
            キャンセル
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: 'CustomConfirm',
  props: {
    type: {
      type: String,
      default: 'warning',
    },
    message: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    display: false,
  }),
  watch: {
    value(v) {
      this.display = v;
    }
  },
  methods: {
    open() {
      this.display = true;
    },
    close() {
      this.display = false;
    },
    ok() {
      this.close();
      this.$emit('click-ok');
    },
    cancel() {
      this.close();
      this.$emit('click-cancel');
    },
    confirm() {
      this.open();
      return new Promise(resolve => {
        this.$once('click-ok', () => resolve(true));
        this.$once('click-cancel', () => resolve(false));
      });
    }
  },
};
</script>