import api from './api';

export default {
  async search(params) {
    return await api.get(`/aggregators/${params.contract_aggregator_id}/contract_division/${params.contract_division_code}/dates/${params.target_date}/report`);
  },
  async searchReport(contract_id, params) {
    return await api.get(`/contract/${contract_id}/dates/${params.target_date}/report_download/report`);
  },
  async confirm(contract_aggregator_id, payload) {
    return await api.put(`/aggregators/${contract_aggregator_id}/report_confirm`, payload );
  },
  async download(params,params2,reportId) {
    return await api.get(`/files/aggregators/${params2.contract_aggregator_id}/contract/${params.contract_id}/report/${reportId}/dates/${params2.target_date}/report_download`, { params, responseType:'blob', dataType:'binary' });
  },
}