export const state = {
  show: false,
  text: '',
  param: {},
};

export const getters = {
}

export const mutations = {
  UPDATE (state, {show, text, param} ) {
    state.show = show;
    state.text = text || '処理中です。しばらくお待ちください。';
    state.param = param || {};
  },
};

export const actions = {
  progressStart( context, text, param ) {
    context.commit('UPDATE', { show: true, text, param } );
  },
  // progressSet( context, text, param ) {
  //   context.commit('UPDATE', { show: true, text, param } );
  // },
  // progressInc( context, text, param ) {
  //   context.commit('UPDATE', { show: true, text, param } );
  // },
  progressDone( context ) {
    context.commit('UPDATE', { show: false, text: null, param: {} } );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};