<template> 
  <v-menu
    v-model="isDisplay"
    :close-on-content-click="false"
    persistent
    offset-overflow
    :activator="activator"
    content-class="v-small-dialog__menu-content"
    eager
    min-width="360"
  >
    <div class="v-small-dialog__content">
      <v-form ref="editorForm" class="mt-2" @submit.prevent>
        <v-text-field
          label="Edit"
          outlined
          dense
          autofocus
          @keyup.enter="save"
          :value="value"
          @change="v => value = v"
          :type="type"
          :class="className"
          :rules="rules"
          :maxlength="maxlength"
        ></v-text-field>
      </v-form>
    </div>
    <div class="v-small-dialog__actions">
      <v-btn
        depress
        text
        color="primary"
        @click.stop="close"
      >
        CANCEL
      </v-btn>
      <v-btn
        depress
        text
        color="primary"
        @click="save"
      >
        設定
      </v-btn>
    </div>
  </v-menu>
</template>

<script>

export default {
  namme: 'TableEditMenu',
  inheritAttrs: false,
  data: () => ({
    isDisplay: false,
    activator: null,
    value: null,
    input: null,
    type: null,
    className: null,
    rules: [],
    maxlength: null,
  }),
  computed: {
  },
  methods: {
    init( activator ) {
      this.activator = activator;
      this.isDisplay = false;
    },
    show ( { value, activator, input, type, rules, maxlength } ) {
      if (this.activator !== activator) return;
      this.value = value;
      this.activator = activator;
      this.input = input; // callback
      if (type !== 'text') {
        this.type = 'tel';
        this.className = 'g-input-right';
      }
      this.rules = rules;
      this.$nextTick(()=>{
        let input = this.$refs.editorForm.$el.querySelector('input:not([type=hidden])');
        input.focus();
        input.select();
      });
      this.maxlength = maxlength;
    },
    save () {
      if ( this.$refs.editorForm.validate() ) {
        if (this.input) this.input( this.value );//callback
        this.close();
      }
    },
    close () {
      this.isDisplay = false;
    }
  },
}
</script>