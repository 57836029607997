import api from './api';
import { deepClone } from '@/util';

export default {
  async requestForecast(params,items) {
    let payload = deepClone(params);
    payload.resource_list = deepClone(items);

    return await api.post(`/analysis_report_request/prediction`, payload);
  },
  async requestBalance(params) {
    let payload = deepClone(params);

    return await api.post(`/aggregators/${params.aggregator_id}/analysis_report_request/bop`, payload);
  },
  async requestImBalance(params) {
    let payload = deepClone(params);

    return await api.post(`/aggregators/${params.aggregator_id}/analysis_report_request/imbalance`, payload);
  },
  async requestBalanceFile(analysis_report_id,file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/analysis_report_request/${analysis_report_id}/premium`, form,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async searchResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/analysis_report_request/resources`, { params });
  }
}