import api from './api';

const service = {
    // 発電販売計画（FIT） 照会
    async searchSubmissionSalesPlan(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/plan/fit`);
    },
    // 発電販売計画（FIT） OCCTO提出
    async updateSubmissionOctto(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/plan/fit/upload`)
    },
    // 発電販売計画受付（FIT） 検索
    async searchReceptionSalesPlan(aggregator_id,params) {
        const baseUrl = `/aggregators/${aggregator_id}/electric_power/trading/plan/fit/acceptance`;
        const paramsUrl = Object.entries(params).map((e) => {
            let key = e[0];
            let value = e[1];
            return `${key}=${value}`;
          }).join("&");
        const url = baseUrl + '?' + paramsUrl;
        return await api.get(url);
    },
    // 発電販売計画（FIT） ファイルアップロード
    async uploadSalesPlan(params, file) {
      const form = new FormData();
      form.append('file', file);
      return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/plan/fit/file_upload`, form,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },
    // 発電販売計画自動提出フラグ（FIT） 照会
    async searchAutoSubmissionConfig(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/electric_power/trading/plan/fit/automatic_submission`)
    },
    // 発電販売計画自動提出フラグ（FIT） 更新
    async updateAutoSubmissionConfig(params){
        return await api.put(`/aggregators/${params.aggregator_id}/electric_power/trading/plan/fit/automatic_submission?occto_auto_fit_flag=${params.occto_auto_fit_flag}` )
    },
}

export default service;
