import api from './api';

const service = {
  async upload(file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/nsc_low_resource/stop/upload`, form, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
    });
  }, 
  async downloadTemplate() {
      return await api.get(`/files/csv/nsc_low_resource/stop/templete`, { responseType:'blob', dataType:'binary' });
  },
}

export default service;
