import api from './api';

export default {
  async search(params) {
    return await api.get(`aggregators/${params.aggregator_id}/simulation_result`, { params });
  },
  async download(params) {
    return await api.get(`/files/templates/electric_power/actual/resource_types/${params.resource_type}/${params.minutes}`, { params, responseType:'blob', dataType:'binary' });
  },
  async downloadForecastPrevious(params, resource_type) {
    return await api.get(`/files/csv/simulations/${params.simulation_id}/resource_types/${resource_type}/electric_power/forecast_and_actual/previous_day`, { responseType:'blob', dataType:'binary' });
  },
  async downloadForecastToday(params, resource_type) {
    return await api.get(`/files/csv/simulations/${params.simulation_id}/resource_types/${resource_type}/electric_power/forecast_and_actual/same_day`, { responseType:'blob', dataType:'binary' });
  },
  async downloadSpotMarketPrice(params) {
    return await api.get(`/files/csv/simulations/${params.simulation_id}/spot_market/price/forecast_and_actual/previous_day`, { responseType:'blob', dataType:'binary' });
  },
  async downloadSameDayMarketPrice(params) {
    return await api.get(`/files/csv/simulations/${params.simulation_id}/same_day_market/price/forecast_and_actual/previous_day`, { responseType:'blob', dataType:'binary' });
  },
  async downloadGeneratorElectricPower(params) {
    return await api.get(`/files/csv/simulations/${params.simulation_id}/resources/generators/electric_power/plan_and_actual`, { responseType:'blob', dataType:'binary' });
  },
  async downloadBatteryPlan(params) {
    return await api.get(`/files/csv/simulations/${params.simulation_id}/resources/batteries/operation/plan/`, { responseType:'blob', dataType:'binary' });
  },
  async downloadTradingArea(params) {
    return await api.get(`/files/csv/simulations/${params.simulation_id}/areas/trading`, { responseType:'blob', dataType:'binary' });
  },
  async downloadTradingBgroup(params) {
    return await api.get(`/files/csv/simulations/${params.simulation_id}/balancing_groups/trading`, { responseType:'blob', dataType:'binary' });
  },
  async downloadTradingPplant(params) {
    return await api.get(`/files/csv/simulations/${params.simulation_id}/power_plants/trading`, { responseType:'blob', dataType:'binary' });
  },
}