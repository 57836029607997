import api from './api';

const service = {
  async searchByBgroup(params, item) {
    // データ取得
    let data = await api.get(`/aggregators/${params.aggregator_id}/resource-status/bgroups/${params.bgroup_id}?target_date=${item.target_date}`, { params });
    const targetData = data.data[0];
    const current = targetData.last_time_code_type;
    // 発電所一覧
    const pplants = targetData.pplant_list;
    
    // 市場調達
    const procurement = targetData.procurement_list;
    
    var eclimit = null;
    var iulimit = null;
    if(item != undefined){
      // 発電量上限値固定
      if(item.electric_upper_limit != undefined){
        eclimit = item.electric_upper_limit;
      } 
      // インバランス上限値固定
      if(item.imbalance_upper_limit != undefined){
        iulimit = item.imbalance_upper_limit;
      }
    }

    return { data: { list: targetData, current, pplants, eclimit, iulimit, procurement } };
  },
  // リスト取得 (バランシンググループ)
  async getBgroup(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/resource-status/bgroups?area_id=${params.energy_area_id}`, { params });
  },
}

export default service;
