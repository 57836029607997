import api from './api';

const service = {
  async create() {
    return await api.get(`/files/templates/eprx_upload`, { responseType:'blob', dataType:'binary' });
  },

  async uploadBalancedMarket(file, aggregator_id, area_id) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/${aggregator_id}/eprx_upload/${area_id}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
}

export default service;