import api from './api';

const service = {

  async search(params) {
    //let res = await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/negotiation/trading/plan`);
    //res.data.sell_outside_area.forEach( item => {
    //  // 入力チェックメッセージ用
    //  item._message = Object.keys(item).reduce( (message, key) => {
    //    message[key] = [];
    //    return message;
    //  }, {});
    //  // 元の値を保持
    //  item._origin = JSON.parse(JSON.stringify(item));
    //});
    
    //return res;
    return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/negotiation/trading/plan`);
  },
  async update(params, payload) {
    return await api.put(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/negotiation/trading/plan`, payload);
  },
}

export default service;