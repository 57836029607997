// window.onerror = function (msg, url, line, col, error) {
//   //code to handle or report error goes here
//   console.log('window onerror', msg, url, line, col, error);
// }
// window.addEventListener('unhandledrejection', function(event) {
//   //handle error here
//   //event.promise contains the promise object
//   //event.reason contains the reason for the rejection
//   console.log('unhandledrejection', event);

// });

// 共通エラー処理
export default async (err, vm, info) => {
  console.error('errors.handler', err, vm ,  info);
  
  // Progressの非表示
  try {
    vm.$store.dispatch('progress/progressDone');
  } catch(e) {
    //console.error(e);
  }
  try {

    if (err.isAxiosError) {
      console.error('errors.response',err.response);
      // セッションタイムアウト
      if ( err.response.status === 401 ) {
        let ret = await vm.$confirmWarning('セッションタイムアウトしました。\nログインしなおしますか？')
        if (ret) {
          localStorage.clear();
          window.location.href = "/";
        }
      } else {
        // APIコール時のエラー
        await vm.$dialogError('処理中に異常が発生しました。');
      }
    }

  } catch(e) {
    //console.error(e);
  }
  try {
    // エラー情報の送信
  } catch (e){
    console.error(e);
  }
}

