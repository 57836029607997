import api from './api';

const service = {
    async searchPplant(aggregator_id,params) {
        return await api.get(`/aggregators/${aggregator_id}/power_plants/trading/`, { params });
      },
    async downloadArea(params) {
      return await api.get(`/files/csv/aggregators/${params.aggregator_id}/areas/${params.area_id}/trading/`,{ params, responseType:'blob', dataType:'binary' });
    },
    async downloadBgroup(params) {
      return await api.get(`/files/csv/aggregators/${params.aggregator_id}/balancing_groups/${params.bgroup_id}/trading/`,{ params, responseType:'blob', dataType:'binary' });
    },
    async downloadPplant(params) {
      return await api.get(`/files/csv/aggregators/${params.aggregator_id}/power_plants/${params.pplant_id}/trading/`,{ params, responseType:'blob', dataType:'binary' });
    },
}

export default service;