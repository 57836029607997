<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    offset-overflow
    :nudge-top="24"
  >
    <!-- text-field -->
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="disp"
        :label="label"
        append-icon="mdi-clock-outline"
        readonly
        :rules="rules"
        :outlined="outlined"
        :dense="dense"
        :clearable="clearable"
        v-bind="attrs"
        v-on="on"
        @click:append="menu = true"
        @click:clear="clear"
      ></v-text-field>
    </template>

    <!-- picker -->
    <v-card>
      <v-card-text>
        <v-card-title class="headline">
          {{first ? startLabel : endLabel + ' (' + this.startValue + ' ~)'}}
        </v-card-title>
        <v-date-picker
          class="mr-3"
          v-model="date"
          :min="minDate"
          :max="maxDate"
        >
        </v-date-picker>
        <v-time-picker
          class="mr-3"
          v-model="time"
          scrollable
          ref="timePicker"
          :min="minTime"
          :max="maxTime"
        ></v-time-picker>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="cancel"
        >
          CANCEL
        </v-btn>
        <v-btn
          v-if="first"
          :disabled='date == null || time == null'
          text
          color="primary"
          @click="next"
        >
          次へ
        </v-btn>
        <v-btn
          v-else
          :disabled='date == null || time == null'
          text
          color="primary"
          @click="save"
        >
          設定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'DateTimeRangeTextField',
  inheritAttrs: false,
  props: {
    start: {
      type: String,// YYYY-MM-DD hh:mm
    },
    end: {
      type: String,// YYYY-MM-DD hh:mm
    },
    label: {
      type: String,
    },
    startLabel: {
      type: String,
      default:'開始日時',
    },
    endLabel: {
      type: String,
      default:'終了日時',
    },
    dense: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    rules: {
      type: Array,
    },
    min: {
      type: String,// YYYY-MM-DD hh:mm
    },
    max: {
      type: String,// YYYY-MM-DD hh:mm
    },
    clearable: {
      type: Boolean,
    }
  },
  data: () => ({
    menu: false,
    startValue: '',
    endValue: '',
    date: null,
    time: null,
    first: true,
    disp: '',
    minDate: null,
    maxDate: null,
    minTime: null,
    maxTime: null,
  }),
  computed: {
  },
  watch: {
    start () {
      if (this.start === '') this.startValue = '';
      else this.startValue = this.start;
      this.display();
    },
    end () {
      if (this.end === '') this.endValue = '';
      else this.endValue = this.end;
      this.display();
    },
    menu () {
      if ( this.menu ) {
        this.init();

      }
    },
    date (v) {
      if ( !this.first ) {
        // 同じ日付の場合は時間の最小を指定する
        const dt = this.startValue.split(' ');
        if (dt[0] === v) {
          this.minTime = dt[1];
          if ( !( this.minTime <= this.time ) ) {//入力途中time=nullを考慮した条件
            // 既に下回っている場合またはクリアする
            this.time = null;
            // 入力途中の場合（時間または分のみ入力）は入力エリアをクリアする
            this.$refs.timePicker.inputHour = null;
            this.$refs.timePicker.inputMinute = null;
            this.$refs.timePicker.selecting = 1;
          }
        } else {
          this.minTime = null;
        }
      }
    },
  },
  created () {
    this.reset();
  },
  methods: {
    init() {
      // 一日目の設定
      this.first = true;
      this.$nextTick( ()=> {
        this.$refs.timePicker.selecting = 1;
      });
      // 範囲設定
      this.minDate = this.min;
      this.maxDate = this.max;
      this.minTime = null;
      this.maxTime = null;

      if ( !this.isEmpty(this.startValue) ){
        const dt = this.startValue.split(' ');
        if (dt.length === 2) {
          this.date = dt[0];
          this.time = dt[1];
          return;
        }
      }
      this.date = null;
      this.time = null;
    },
    next () {
      // 二日目の設定
      this.first = false;
      this.$refs.timePicker.selecting = 1;

      // 範囲設定
      this.minDate = this.date; //開始日を最小とする
      this.maxDate = this.max;
      this.minTime = null;
      this.maxTime = null;

      this.startValue = this.date + ' ' + this.time;
      if ( !this.isEmpty(this.endValue) ){
        const dt = this.endValue.split(' ');
        const dtStart = this.startValue.split(' ');
        if (dt.length === 2) {
          this.date = dt[0];
          this.time = dt[1];
            // 同じ日付の場合は時間の最小を指定する
            if (dt[0] === dtStart[0]) {
              this.minTime = dtStart[1];
              if ( !( this.minTime <= this.time ) ) {
                // 時間の最小値が既に下回っている場合またはクリアする
                this.time = null;
                this.$refs.timePicker.inputHour = null;
                this.$refs.timePicker.inputMinute = null;
                this.$refs.timePicker.selecting = 1;
              }
            }else if(dt[0] < dtStart[0]){
              this.date = null;
            }
          return;
        }
      }
      this.date = null;
      this.time = null;
    },
    save () {
      if (this.first) {
        this.startValue = this.date + ' ' + this.time;
      } else {
        this.endValue = this.date + ' ' + this.time;
      }
      this.display();
      this.menu = false;
      this.$emit('update:start', this.startValue);
      this.$emit('update:end',this.endValue);
    },
    cancel() {
      this.reset();
      this.menu=false;
    },
    clear () {
      this.endValue = '';
      this.startValue = '';
      this.$emit('update:start', this.startValue);
      this.$emit('update:end',this.endValue);
      this.menu = false;
    },
    display () {
      if ( ( this.isEmpty(this.startValue) ) &&
        ( this.isEmpty(this.endValue) ) ) {
        this.disp = '';
      } else {
        this.disp = (this.startValue||'' ) + ' ~ ' + (this.endValue||'');
      }
    },
    reset () {
      if ( this.isEmpty(this.start) ) this.startValue = '';
      else this.startValue = this.start;
      if ( this.isEmpty(this.end) ) this.endValue = '';
      else this.endValue = this.end;
    },
    isEmpty (v) {
      return v === null || v === '' || v === undefined;
    }
  },
}
</script>
