import api from './api';

const service = {
  // テンプレート 照会
  async getTemplate(param) {
    return await api.get(`/aggregators/${param.aggregator_id}/mail_create_send/mail_template`);
  },
  
  // 送信履歴 照会
  async getHistory(param) {
    return await api.get(`/aggregators/${param.aggregator_id}/mail_create_send/mail_history`);
  },
  
  // メール送信
  async postEmail(param, payload) {
    return await api.post(`/aggregators/${param.aggregator_id}/mail_create_send/mail_send`, payload);
  },
  
  // テンプレート 更新
  async putTemplate(param, payload) {
    return await api.put(`/aggregators/${param.aggregator_id}/mail_create_send/mail_template`, payload);
  },
}

export default service;