import graph from './graph';

export default {
    //cookie設定
    expirationDate:"2m",
    link: {
        "toshiba": {
            "url": "https://www.global.toshiba/jp/top.html",
            "contents": {
                "data": require("@/assets/Toshiba_Logo_Red_RGB.png"),
                "type": "img/gif"
            },
        },
        "ess": {
            "url": "https://www.global.toshiba/jp/company/energy.html",
            "contents": {
                "data": "東芝エネルギーシステムズ株式会社トップページ",
                "type": "text"
            },
        },
        "privacy": {
            "url": "https://www.global.toshiba/jp/privacy/energy.html",
            "contents": {
                "data": "個人情報保護方針",
                "type": "text"
            },
        },
        "copyright": {
            "url": "https://www.global.toshiba/jp/c_right/energy.html",
            "contents": {
                "data": "Copyright © " +
                    new Date().getFullYear() +
                    " Toshiba Energy Systems & Solutions Corporation, All Rights Reserved.",
                "type": "text"
            },
        },
    },
    graph,
}