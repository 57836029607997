import api from './api';

const service = {
  async searchResource(params, condition, reload) {
    // データ取得
    let res = await api.get(`/aggregators/${condition.aggregator_id}/low-resource-status/nsc_low_resources/${params.nsc_generation_contractor_code}/${params.nsc_receiving_electricity_point_specific_no}?target_date=${condition.target_date}`, {params:reload});
    if (res.data.message) {
      return res;
    }
    const targetData = res.data[0];
    const current = targetData.last_time_code_type;
   
    return { data: { list: targetData, current } };
  },
  // リスト取得 (リソース)
  async getResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/low-resource-status/nsc_low_resources?area_id=${params.energy_area_id}&resource_kind=${params.resource_type_code}`);
  },
}

export default service;
