<template>
<!--Start Toolbar-->
  <v-toolbar height="40px" style="z-index:100" class="l-menu">
    <v-container fluid fill-height style="padding: 0px 0px 0px;">
      <v-navigation-drawer app v-model="drawer" temporary hide-overlay overlay-opacity="10" width="350px" class="toolbar-height">
        <v-list-item>
          <v-list-item-title class="title pl-5">
            メニュー
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list nav style="text-align:left;">
          <v-list-item v-for="( menu1, index) in MstDisplayInfo.level1" :key="index">
            <v-list-group no-action sub-group style="padding-left: 0px !important;width:100%">
              <template v-slot:activator>
                <v-list-item-content class="l-i-content">
                  <v-list-item-title>{{ menu1.displayName }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <!-- 2階層目直下のメニュー -->
              <v-list-item v-for="( menu2, index ) in menu1.level2" :key="index" :to="menu2.displayUrl"
              class="l-item menu-border" v-show="menu2.displayUrl != '/'">
                <v-list-item-icon style="padding:0px 10px 0px 10px" @click="jump(menu2.displayUrl)">
                  <v-icon>{{ menu2.displayIcon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content @click="jump(menu2.displayUrl)">
                  <v-list-item-title>{{ menu2.displayName }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- 3階層目がある場合のメニュー -->
              <v-list-item v-for=" ( menu2, index ) in menu1.level2" :key="`sub-${index}`" :to="menu2.displayUrl"
               class="l-item" v-show="menu2.displayUrl == '/'">
                <v-list-group no-action sub-group style="padding-left: 0px !important;width:100%">
                  <template v-slot:activator>
                    <v-list-item-content class="l-i-content">
                      <v-list-item-title>{{ menu2.displayName }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item v-for="( menu3, index) in menu2.level3" :key="index"
                    class="l-item menu-border" @click="jump(menu3.displayUrl)">
                    <v-list-item-icon style="padding:0px 10px 0px 10px">
                      <v-icon>{{ menu3.displayIcon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ menu3.displayName }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list-item>
            </v-list-group>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" style="width: 24px !important;height: 24px !important;">
        <v-icon color="white">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <span style="float: left; padding-left:10px">{{ dispName }}</span>
    </v-container>
  </v-toolbar>
<!--End Toolbar-->
</template>

<script th:inline="javascript">
"use strict";
import config from '@/config';

export default {
  components: {},
  data: () => ({
    width: window.innerWidth,
    height: window.innerHeight,
    items: [{ icon: 'exit_to_app', title: "ログアウト" }],
    dispName: null,
    essLinkInfo: config.link.ess,
    tochibaLinkInfo: config.link.toshiba,
    drawer: false,
    MstDisplayInfo: [],
  }),
  methods: {
    jump(link){
      if(link == "/"){
        return
      }else{
        window.location.href = "." + link;
      }
    }
  },
  created() {
  },
  mounted() {
    var jsonStr = localStorage.getItem("menuJson");
    this.MstDisplayInfo = JSON.parse(jsonStr);
    // 現在ページの判定
    if(this.MstDisplayInfo != null && this.MstDisplayInfo.level1 != null){
      for(let i = 0; i < this.MstDisplayInfo.level1.length; i++){
        if(this.MstDisplayInfo.level1[i].level2 == undefined ||
            this.MstDisplayInfo.level1[i].level2 == null){
          continue;
        }else{
          for(let j = 0; j < this.MstDisplayInfo.level1[i].level2.length; j++){
            if(this.MstDisplayInfo.level1[i].level2[j] == undefined ||
                this.MstDisplayInfo.level1[i].level2[j] == null){
              continue;
            }else{
              if(this.MstDisplayInfo.level1[i].level2[j].displayUrl == location.pathname || 
                "/rebset1" + this.MstDisplayInfo.level1[i].level2[j].displayUrl == location.pathname ||
                "/rebset2" + this.MstDisplayInfo.level1[i].level2[j].displayUrl == location.pathname ){
                this.dispName = this.MstDisplayInfo.level1[i].level2[j].displayName;
                break;
              }else{
                for(let k = 0; k < this.MstDisplayInfo.level1[i].level2[j].level3.length; k++){
                  if(this.MstDisplayInfo.level1[i].level2[j].level3[k] != undefined ||
                    this.MstDisplayInfo.level1[i].level2[j].level3[k] == null){
                    if(this.MstDisplayInfo.level1[i].level2[j].level3[k].displayUrl == location.pathname || 
                    "/rebset1"+ this.MstDisplayInfo.level1[i].level2[j].level3[k].displayUrl == location.pathname || 
                    "/rebset2"+ this.MstDisplayInfo.level1[i].level2[j].level3[k].displayUrl == location.pathname ){
                      this.dispName = this.MstDisplayInfo.level1[i].level2[j].level3[k].displayName;
                      break;
                    }else{
                      continue;
                    }
                  }else{
                    continue;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 帯の色 */
.l-menu {
     background-color:#1565C0!important; 
}
.l-menu span {
  color:white!important;
}

.v-list-group__header{
  background-color:transparent!important;
  //mod color:white!important; 
  color:black!important;
  padding: 0px !important;
  //mod border: 1px solid white;
  border: 1px solid black;
}

.v-list-item {
  background-color:transparent!important;
  //mod color:white!important;
  color:black!important;
  padding: 0px !important;
}

.v-list-item__icon {
  align-self: flex-start;
  margin: 16px 0px 16px 0px !important;
}

.v-list-item__action, .v-list-item__avatar, .v-list-item__icon {
  display: inline-flex;
  min-width: 5px;
}

.l-item {
  width:93%; 
  float: right;
}

.l-i-content {
  padding-left: 15px !important;
}

.toolbar-height {
  max-height:90vh;
}

.menu-border{
  //mod border: 1px solid white;
  //border: 1px solid black;
}
</style>
