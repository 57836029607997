import api from './api';

export default {
  async search(params) {
    return await api.get(`/aggregators/${params.contract_aggregator_id}/contract_division/${params.contract_division_code}/dates/${params.target_date}/contract_notice`, { params });
  },
  async searchReport(contract_id, params) {
    return await api.get(`/contract/${contract_id}/dates/${params.target_date}/contract_notice/report`, { params });
  },
  async calculateRequest(params) {
    let url = `/dates/${params.target_date}/${params.kind}/contract_notice/calculate`;
    if(params.kind !== 3) {
      url = url.concat(`?contract_aggregator_id=${params.contract_aggregator_id}`)
    }
    return await api.put(url);
  },
  async mailRequest(params) {
    return await api.put(`/contract/${params.contract_id}/dates/${params.target_date}/${params.kind}/contract_notice/mail`);
  },
  async download(params,reportId) {
    return await api.get(`/files/aggregators/${params.contract_aggregator_id}/contract/${params.contract_id}/report/${reportId}/dates/${params.target_date}/contract_notice`, { params, responseType:'blob', dataType:'binary' });
  },
}