import api from './api';

const service = {
  async searchResource(params, item) {
    // データ取得
    let data = await api.get(`/aggregators/${params.aggregator_id}/resource-status/resources/${params.resource_id}?target_date=${item.target_date}`, { params });
    const targetData = data.data[0];
    const current = targetData.last_time_code_type;
    // リソース一覧
    const resources = targetData.resource_list;
    
    var eclimit = null;
    var iulimit = null;
    if(item != undefined){
      // 発電量上限値固定
      if(item.electric_upper_limit != undefined){
        eclimit = item.electric_upper_limit;
      } 
      // // インバランス上限値固定
      // if(item.imbalance_upper_limit != undefined){
      //   iulimit = item.imbalance_upper_limit;
      // }
    }

    return { data: { list: targetData, current, resources, eclimit, iulimit } };
  },
  async searchResource1Min(params, resource_id) {
    // データ取得
    let data = await api.get(`/aggregators/${params.aggregator_id}/resource-status/resources-1min/${resource_id}?target_date=${params.target_date}`, { params });
    return data.data[0];
  },
  // リスト取得 (リソース)
  async getResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/resource-status/resources?area_id=${params.energy_area_id}&resource_type=${params.resource_type}`, { params });
  },
}

export default service;
