import api from './api';

const service = {

  // リソース検索処理
  async searchResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/mode/${params.mode}`, { params });
  },
  // 発電実績アップロード
  async uploadResult(params, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/${params.aggregator_id}/${params.resource_id}/${params.resource_type}/electric_power/actual/${params.minutes}`, form,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }, 
  // 発電実績テンプレート取得
  async createTemplete(params) {
    return await api.get(`/files/templates/electric_power/actual/resource_types/${params.resource_type}/${params.minutes}`, { params });
  },

  // 発電計画アップロード
  async uploadPlan(params, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/${params.aggregator_id}/${params.resource_id}/${params.resource_type}/electric_power/plan/${params.minutes}`, form,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }, 
  // 発電計画テンプレート取得
  async createTempletePlan(params) {
    return await api.get(`/files/templates/electric_power/plan`, { params });
  },
  // 発電所検索処理
  async searchPplant(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/data_register_pplant_list/`, { params });
  },
  // 出力抑制情報アップロード
  async uploadOutputSuppression(params, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/${params.aggregator_id}/${params.pplant_id}/output_suppression/${params.schedule}`, form,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }, 
  // 出力抑制情報テンプレート取得
  async createTempleteOutputSuppression(params) {
    return await api.get(`/files/templates/output_suppression`, { params, responseType: 'blob', dataType: 'binary' });
  },

  // 需要拠点検索(一覧)
  async searchDpoint(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dpoint_list`, { params });
  },
  // 受電電力実績アップロード
  async uploadDemand(params, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/${params.aggregator_id}/${params.dpoint_id}/demand/actual/minutes/${params.minutes}`, form,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }, 
  // 受電電力実績テンプレート取得
  async createTempleteDemand(params) {
    return await api.get(`/files/templates/demand/actual/${params.minutes}`, { params, responseType: 'blob', dataType: 'binary' });
  },
}

export default service;
