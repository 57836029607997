import api from './api';

const service = {
  async searchResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/resources_stop`, { params });
  },
  async search(params) {
    let aggregator_id = params.aggregator_id;
    let resource_id = params.resource_id;
    let queryParams = {
      target_date: params.target_date,
      resource_type: params.resource_type
    }
    return await api.get(`/aggregators/${aggregator_id}/resources/${resource_id}/stop`, { params: queryParams })
  },
  async create(aggregator_id, resource_id, resource_type, payload) {
    return await api.post(`/aggregators/${aggregator_id}/resources/${resource_id}/stop?resource_type=${resource_type}`, payload)
  },
  async update(aggregator_id, resource_id, resource_type, payload) {
    return await api.put(`/aggregators/${aggregator_id}/resources/${resource_id}/stop?resource_type=${resource_type}`, payload)
  },
  async delete(aggregator_id, resource_id, resource_type, payload) {
    return await api.delete(`/aggregators/${aggregator_id}/resources/${resource_id}/stop?resource_type=${resource_type}`, {
      data: payload
    });
  }
}

export default service;
