import api from './api';
import { isEmpty } from '../util'

const service = {
    async searchBatteryList(aggregator_id, params) {
        return await api.get(`/aggregators/${aggregator_id}/resources/batteries`, { params: { area_id: params.area_id, bgroup_id: params.bgroup_id } });
    },

    async searchBattery(aggregator_id, resource_id) {
        return await api.get(`/aggregators/${aggregator_id}/resources/batteries/${resource_id}`, { params: {} });
    },

    async getBatteryOperation(aggregator_id, resource_id, target_date, reload) {
        let params = {params: {reload}}
        if(isEmpty(reload)) {
            params = {}
        }
        return await api.get(`/aggregators/${aggregator_id}/resources/batteries/${resource_id}/dates/${target_date}/operation/actual`, params);
    },

    async putBatteryPlan(aggregator_id, resource_id, target_date, registerKind, payload) {
        return await api.put(`/aggregators/${aggregator_id}/resources/batteries/${resource_id}/dates/${target_date}/operation/plan?registerKind=${registerKind}`, payload);
    },

    async putAlgorithm(aggregator_id, resource_id, target_date) {
        return await api.put(`/aggregators/${aggregator_id}/resources/batteries/${resource_id}/dates/${target_date}/operation/algorithm`, {});
    },

    // 充放電計画 参考値反映
    async putReferenceQuantity(aggregator_id, area_id, target_date) {
        return await api.put(`/aggregators/${aggregator_id}/areas/${area_id}/dates/${target_date}/operation/reference-quantity`, {});
    },

    // 運転計画アップロード
    async uploadPlan(file, registerKind) {
        const form = new FormData();
        form.append('file', file);
        return await api.post(`/files/csv/resources/batteries/operation/plan/upload?registerKind=${registerKind}`, form,{
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        });
    }, 
    // 運転計画テンプレート取得
    async createTempletePlan() {
        return await api.get(`/files/csv/resources/batteries/operation/plan/download/templete`, {});
    },
}

export default service;
